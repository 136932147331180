/* open-sans-condensed-300normal - all */
@font-face {
  font-family: 'Open Sans Condensed';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Open Sans Condensed Light'),
    local('OpenSansCondensed-Light'), 
    url('./files/open-sans-condensed-all-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/open-sans-condensed-all-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-condensed-300italic - all */
@font-face {
  font-family: 'Open Sans Condensed';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src:
    local('Open Sans Condensed Light Italic'),
    local('OpenSansCondensed-LightItalic'), 
    url('./files/open-sans-condensed-all-300-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/open-sans-condensed-all-300-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-condensed-700normal - all */
@font-face {
  font-family: 'Open Sans Condensed';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Open Sans Condensed Bold'),
    local('OpenSansCondensed-Bold'), 
    url('./files/open-sans-condensed-all-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/open-sans-condensed-all-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
